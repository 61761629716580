<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
    <div v-if="Tickets !== null">
      <div
          class="body-content-overlay"
      />

      <!-- Main Area -->
      <section class="chat-app-window w-100">
        <!-- Chat Content -->
        <div
            class="active-chat"
        >
          <!-- Chat Navbar -->
          <div class="chat-navbar">
            <header class="chat-header py-1">
              <!-- Avatar & Name -->
              <div class="d-flex align-items-center">
                <span>{{ ticketDetail.content }}</span>
              </div>
              <b-button
                  type="button"
                  @click="$router.back()"
                  variant="primary"
              >
                Back
              </b-button>
            </header>
          </div>

          <!-- User Chat Area -->
          <vue-perfect-scrollbar
              ref="refChatLogPS"
              :settings="perfectScrollbarSettings"
              class="user-chats scroll-area position-relative"
              style="height: 70vh;overflow-y: scroll"
          >
            <ticket-log
                v-for="Ticket in Tickets"
                :chat-data="Ticket"
            />
          </vue-perfect-scrollbar>

          <!-- Message Input -->
          <b-form
              class="chat-app-form"
              @submit.prevent
          >
            <b-button

                type="button"
                variant="primary"
                @click="SendMessage"
            >
              Send
            </b-button>
            <feather-icon v-b-modal.modal-upload class="text-warning ml-1" icon="PaperclipIcon"
                          size="35" @click="showUploadModal = true"
            />
            <b-input-group class="input-group-merge form-send-message ml-1">
              <b-form-input
                  v-model.trim="answerTicket.content"
                  placeholder="Answer"
                  @keydown.enter="SendMessage"
              />
            </b-input-group>
          </b-form>
        </div>
      </section>
    </div>

    <!--  Upload Pic Modal   -->
    <b-modal
        v-if="showUploadModal"
        id="modal-upload"
        cancelTitle="Close"
        centered
        ok-title="Send"
        title="Send Picture"
        @cancel="closeUploadModal"
        @ok="submitUploadMediaModal"
    >
      <b-media class="my-2">
        <template>
          <b-avatar
              ref="previewEl"
              :src="answerTicket.pictureUrl"
              class="w-100 cursor-pointer flex-wrap"
              rounded
              size="300px"
              style="object-fit: contain"
              @click.native="$refs.refInputEl1.click()"
          >
            <feather-icon v-if="!answerTicket.pictureUrl" icon="PlusSquareIcon" size="100"/>
            <img v-else :src="answerTicket.pictureUrl" alt="" style="width: 100%;height: 30rem;object-fit: contain">
          </b-avatar>
        </template>
        <div class="d-none flex-wrap">
          <b-button
              variant="primary"
          >
            <input
                ref="refInputEl1"
                accept=".jpg,.jpeg,.png"
                class="d-none"
                type="file"
                @input="uploadMedia"
            >
            <span class="d-none d-sm-inline">+</span>
            <feather-icon
                class="d-inline d-sm-none"
                icon="EditIcon"
            />
          </b-button>
        </div>
      </b-media>

      <b-input-group class="input-group-merge form-send-message mt-2">
        <b-form-input
            v-model.trim="answerTicket.content"
            placeholder="Message .."
        />
      </b-input-group>
    </b-modal>

    <!--  Upload Pic Modal End  -->
  </b-overlay>
</template>

<script>
import { BAvatar, BButton, BForm, BFormInput, BInputGroup, BMedia, BOverlay } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import TicketLog from '../../Ticket/Detail/TicketLog.vue'
import vSelect from 'vue-select'
import { AnswerTicket, TicketGetByIdRequest } from '@/libs/Api/Ticket'

import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { getUserData } from '@/auth/utils'
import Helper from '@/libs/Helper'

export default {
  title: 'Ticket',
  data() {
    return {
      ticketId: this.$route.params.id,
      user: null,
      Tickets: null,
      showUploadModal: false,
      showOverlay: false,
      admin: null,
      base64ImageSrc: null,
      pic: null,
      ticketDetail: '',
      picText: '',
      baseURL: Helper.baseUrl,
      refreshTicket: null,
      attachFileUrl: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      TicketTypesId: [
        {
          option: 'Authorization',
          value: 1
        },
        {
          option: 'Support',
          value: 2
        },
        {
          option: 'Other',
          value: 3
        },
      ],
      ticketType: 0,
      content: '',
      answerTicket: {
        id: this.$route.params.id,
        content: '',
        attachFile: '',
        pictureUrl: ''
      }
    }
  },
  computed: {
    currentDate() {
      let today = new Date()
      let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
      return date + ' ' + time
    },
  },
  watch: {},
  async created() {
    await this.GetAllByParentId()
    const userData = getUserData()
    this.admin = userData
  },
  methods: {
    async GetAllByParentId() {
      let _this = this
      _this.showOverlay = true
      let ticketGetByIdRequest = new TicketGetByIdRequest(_this)
      let data = {
        pageNumber: 1,
        count: 100,
        ticketId: _this.$route.params.id
      }
      ticketGetByIdRequest.setParams(data)
      await ticketGetByIdRequest.fetch((content) => {
        _this.showOverlay = false
        _this.ticketDetail = content.data.data[0]
        _this.Tickets = content.data.data
        _this.scrollToBottom()
      }, (e) => {
        console.log(e)
      })
    },
    async SendMessage() {
      let _this = this
      if (_this.answerTicket.content === '' && _this.answerTicket.attachFile === '') {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: 'Please Write Your Message',
            title: `error`,
            variant: 'danger',
          },
        })
      } else {
        let answerTicket = new AnswerTicket(_this)
        let myForm = await Helper.makeFormData(_this.answerTicket)
        answerTicket.setParams(myForm)
        await answerTicket.sendFormData(() => {
          _this.showOverlay = false
          _this.answerTicket = {
            id: this.$route.params.id,
            content: '',
            attachFile: '',
            pictureUrl: ''
          }
          _this.closeUploadModal()
          _this.GetAllByParentId()
        }, (error) => {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
          console.log(e)
        })
      }
    },
    async uploadMedia() {
      let file = this.$refs.refInputEl1.files[0]
      this.answerTicket.attachFile = file
      this.answerTicket.pictureUrl = URL.createObjectURL(file)
    },

    submitUploadMediaModal() {
      this.content = this.picText
      this.SendMessage()
    },
    closeUploadModal() {
      this.pic = null
      this.picText = null
      this.showUploadModal = false
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.refChatLogPS.$el
        container.scrollTop = container.scrollHeight
      })
    },
  },
  components: {
    BAvatar,
    BForm,
    vSelect,
    BInputGroup,
    BFormInput,
    BButton,
    BOverlay,
    VuePerfectScrollbar,
    TicketLog,
    BMedia
  },
  // setup() {
  //   const CHAT_APP_STORE_MODULE_NAME = 'app-chat'
  //   // UnRegister on leave
  //   onUnmounted(() => {
  //     if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
  //   })
  //
  //   const { resolveAvatarBadgeVariant } = useChat()
  //
  //   // Scroll to Bottom ChatLog
  //   const refChatLogPS = ref(null)
  //   const scrollToBottomInChatLog = () => {
  //     const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
  //     scrollEl.scrollTop = scrollEl.scrollHeight
  //   }
  //
  //   // ------------------------------------------------
  //   // Chats & Contacts
  //   // ------------------------------------------------
  //   const chatsContacts = ref([])
  //   const contacts = ref([])
  //   // ------------------------------------------------
  //   // Single Chat
  //   // ------------------------------------------------
  //   const activeChat = ref({})
  //   const chatInputMessage = ref('')
  //   const openChatOfContact = userId => {
  //     // Reset send message input value
  //     chatInputMessage.value = ''
  //
  //     // if SM device =>  Close Chat & Contacts left sidebar
  //     // eslint-disable-next-line no-use-before-define
  //     mqShallShowLeftSidebar.value = false
  //   }
  //   const perfectScrollbarSettings = {
  //     maxScrollbarLength: 150,
  //   }
  //
  //   // User Profile Sidebar
  //   // ? Will contain all details of profile user (e.g. settings, about etc.)
  //   const profileUserData = ref({})
  //   // ? Will contain id, name and avatar & status
  //   const profileUserDataMinimal = ref({})
  //
  //   // Active Chat Contact Details
  //   const shallShowActiveChatContactSidebar = ref(false)
  //
  //   // UI + SM Devices
  //   // Left Sidebar Responsiveness
  //   const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
  //   const startConversation = () => {
  //     if (store.state.app.windowWidth < $themeBreakpoints.lg) {
  //       mqShallShowLeftSidebar.value = true
  //     }
  //   }
  //
  //   return {
  //
  //     resolveAvatarBadgeVariant,
  //
  //     // Chat & Contacts
  //     chatsContacts,
  //     contacts,
  //
  //     // Single Chat
  //     refChatLogPS,
  //     activeChat,
  //     chatInputMessage,
  //     openChatOfContact,
  //
  //     // Profile User Minimal Data
  //     profileUserDataMinimal,
  //
  //     // User Profile Sidebar
  //     profileUserData,
  //     // Active Chat Contact Details
  //     shallShowActiveChatContactSidebar,
  //
  //     // UI
  //     perfectScrollbarSettings,
  //
  //     // UI + SM Devices
  //     startConversation,
  //     mqShallShowLeftSidebar,
  //   }
  // },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
