<template>
  <div class="chats">
    <div
        class="chat"
        :class="{'chat-left': chatData.responseType !== 'SupportResponse'}"
    >
      <div class="chat-body">
        <div class="chat-content position-relative">
          <img @click="showUploadModal = true" v-b-modal.modal-upload
               v-if="chatData.attachFileUrl" :src="chatData.attachFileUrl"
               style="object-fit: contain;object-position: center" class="my-2 cursor-pointer" width="200px" height="100px" alt="">
          <p class="mb-1">{{ chatData.content }}</p>
          <span class="mr-auto" style="font-size: 13px">
            {{ new Date(chatData.createdAt).toLocaleTimeString('en-US',{hour:"2-digit", minute:'2-digit'})}}
            -
            {{new Date(chatData.createdAt).toLocaleDateString('en-US')}}
          </span>
        </div>
      </div>
    </div>

    <!--  Upload Pic Modal   -->
    <b-modal
        v-if="showUploadModal"
        id="modal-upload"
        centered
        ok-only
        title="Picture"
        okTitle="Close"
        @ok="closeUploadModal"
    >
      <b-media class="my-2">
        <template>
          <img :src="chatData.attachFileUrl" style="width: 100%;height: 30rem;object-fit: contain!important" alt="">

        </template>
      </b-media>
    </b-modal>
    <!--  Upload Pic Modal End  -->

  </div>
</template>

<script>
import {computed} from '@vue/composition-api'
import {
  BAvatar,
  BModal,
} from 'bootstrap-vue'
import DoubleCheckIcon from "@/views/components/icons/DoubleCheckIcon";
import Helper from "@/libs/Helper";
// import { mapGetters } from "vuex";

export default {
  data() {
    return {
      baseURL: Helper.baseUrl,
      showUploadModal: false,
    }
  },
  components: {
    DoubleCheckIcon,
    BAvatar,
    BModal
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    createJalaliDate(param) {
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    closeUploadModal() {
      this.showUploadModal = false;
    },
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.userInfo.userId,
        // avatar: `https://api.skycoach.simagar.com/${props.user.selfieFileData}`,
        avatar: `https://skycoachapi.oregonserver.com/${props.user.selfieFileData}`,
      }

      let chatLog = []
      if (props.chatData.chat) {
        chatLog = props.chatData.chat.chat
      }

      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined
      let msgGroup = {
        sender: chatMessageSenderId,
        messages: [],
      }

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.senderId) {
          msgGroup.messages.push({
            msg: msg.message,
            time: msg.time,
          })
        } else {
          chatMessageSenderId = msg.senderId
          formattedChatLog.push(msgGroup)
          msgGroup = {
            senderId: msg.senderId,
            messages: [{
              msg: msg.message,
              time: msg.time,
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
      }
    })

    return {
      formattedChatData,
    }
  },
}
</script>

<style scoped>
.ticket-img {
  width: 10rem;
  height: 10rem;
  margin-top: 10px;
  background-size: contain;
  background-repeat: no-repeat;
}

.deliver {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
</style>
